import client from '@/api/client'

/**
 * Lists the LDAP groups existent
 * @param {string} groupID
 * @returns {Promise<Array>}
 */
export async function listLdapGroups (groupID) {
  const response = await client.get(`/ldap/groups/search/${groupID}`)
  return response.data
}

/**
 * Gets a specific LDAP group
 * @param {string} groupName
 * @returns {Promise<Object>}
 */
export async function getLdapGroup (groupName) {
  const response = await client.get(`/ldap/groups/${groupName}`)
  return response.data
}

/**
 * Gets the LDAP group's Unit
 * @param {string} groupName
 * @returns {Promise<Object>}
 */
export async function getUnitLdapGroup (groupName) {
  const response = await client.get(`/ldap/groups/unit/${groupName}`)
  return response.data
}

/**
 * Deletes a LDAP group Unit association
 * @param {string} groupName
 * @param {string} unitName
 * @returns {Promise<Object>}
 */
export async function deleteGroupUnitAssociation (adGroup, ldapGroup) {
  await client.delete(`/ldap/groups/ad/${adGroup}/link-ldap/${ldapGroup}`)
}

/**
 * Lists the AD units existent
 * @param {string} query
 * @returns {Promise<Array>}
 */
export async function listADUnits (query) {
  const response = await client.get('/ldap/groups/unit/search', { params: { query } })
  return response.data
}

export async function searchAdUsers (query) {
  const response = await client.get('/ldap/users/ad/search', { params: { query } })
  return response.data
}

/**
 * Gets the Ldap group from a unit
 * @param {string} unitName
 * @returns {Promise<Object>}
 */
export async function getAdGroup (unitName) {
  const response = await client.get(`/ldap/groups/ad/${encodeURIComponent(unitName)}`)
  return response.data
}

/**
 *
 * @param {String} adGroup Name of the group to add
 * @param {String} ldapGroup Name of the ldap group to associate with
 * @param {String} securityGroup The permissions of the AD group that will apply
 */
export async function linkLdapGroupToAdSecurityGroup (adGroup, ldapGroup, securityGroup) {
  const payload = {}
  if (securityGroup) payload.securityGroup = securityGroup
  await client.post(`/ldap/groups/ad/${encodeURIComponent(adGroup)}/link-ldap/${encodeURIComponent(ldapGroup)}`, payload)
}
