import client from '@/api/client'

/**
 * Creates a new MOTD
 * @param {Object} motd The MOTD to create
 * @param {String} motd.title The title of the MOTD
 * @param {String} motd.message The message of the MOTD
 * @param {String} motd.action The action of the MOTD
 * @param {Date} motd.availableAt The date the MOTD is available at
 * @param {Date} motd.expiresAt The date the MOTD expires at
 * @param {Array<String>} motd.users The ids of the users the MOTD is targeted at
 * @param {Array<String>} motd.units The ids of the units the MOTD is targeted at
 * @returns {Promise<Object>} The created MOTD
 */
export async function createMotd ({ title, message, action, availableAt, expiresAt, users, units }) {
  const response = await client.post('/motd', { title, message, action, availableAt, expiresAt, users, units })
  return response.data
}

/**
 * Creates a new MOTD version
 * @param {Object} motd The MOTD version to create
 * @param {String} motd._id The id of the MOTD
 * @param {String} motd.title The title of the MOTD
 * @param {String} motd.message The message of the MOTD
 * @param {String} motd.action The action of the MOTD
 * @param {Date} motd.availableAt The date the MOTD is available at
 * @param {Date} motd.expiresAt The date the MOTD expires at
 * @param {Array<String>} motd.users The ids of the users the MOTD is targeted at
 * @param {Array<String>} motd.units The ids of the units the MOTD is targeted at
 * @returns {Promise<Object>} The created MOTD
 */
export async function createMotdVersion ({ _id, title, message, action, availableAt, expiresAt, users, units }) {
  const response = await client.post(`/motd/${_id}`, { title, message, action, availableAt, expiresAt, users, units })
  return response.data
}

/**
 * Updates a MOTD
 * @param {Object} motd The MOTD to update
 * @param {String} motd._id The id of the MOTD
 * @param {String} motd.title The title of the MOTD
 * @param {String} motd.message The message of the MOTD
 * @param {String} motd.action The action of the MOTD
 * @param {Date} motd.availableAt The date the MOTD is available at
 * @param {Date} motd.expiresAt The date the MOTD expires at
 * @param {Array<String>} motd.users The ids of the users the MOTD is targeted at
 * @param {Array<String>} motd.units The ids of the units the MOTD is targeted at
 * @returns {Promise<Object>} The updated MOTD
 */
export async function updateMotd ({ _id, title, message, action, availableAt, expiresAt, users, units }) {
  const response = await client.put(`/motd/${_id}`, { title, message, action, availableAt, expiresAt, users, units })
  return response.data
}

/**
 * Gets a MOTD by its id
 * @param {String} id The id of the MOTD to get
 * @returns {Promise<Object>} The MOTD
 */
export async function getMotd (motdId) {
  const response = await client.get(`/motd/${motdId}`)
  return response.data
}

/**
 * Gets messages of the day
 * @param {String} search The search query
 * @param {Object} filters The filters to apply
 * @param {Number} limit The maximum number of messages to get
 * @param {Number} offset The number of messages to skip
 * @returns {Promise<Array<Object>>} The messages of the day
 */
export async function getMotds (search = '', filters, limit = 10, offset = 0) {
  const response = await client.get('/motd', {
    params: {
      search,
      sort: filters.sort,
      active: filters.status.active,
      expired: filters.status.expired,
      scheduled: filters.status.scheduled,
      limit,
      offset
    }
  })
  return response.data
}

/**
 * Gets the number of MOTDs
 * @param {String} search The search query
 * @returns {Promise<Number>} The number of MOTDs
 */
export async function getMotdCount (search = '', filters) {
  const response = await client.get('/motd/count', {
    params: {
      search,
      active: filters.status.active,
      expired: filters.status.expired,
      scheduled: filters.status.scheduled
    }
  })
  return response.data
}

/**
 * Gets the MOTD's versions
 * @param {String} id The id of the MOTD
 * @returns {Promise<Array<Object>>} The MOTD's versions
 */
export async function getMotdVersions (motdId) {
  const response = await client.get(`/motd/${motdId}/versions`)
  return response.data
}

/**
 * Expires a MOTD
 * @param {String} id The id of the MOTD to expire
 * @returns {Promise<Object>} The expired MOTD
 */
export async function expireMotd (motdId) {
  const response = await client.delete(`/motd/${motdId}`)
  return response.data
}

/**
 * Searches a MOTD's acks by user
 * @param {String} motdId The id of the MOTD
 * @param {String} [query] The optional search query
 * @param {Array<String>} [units] The optional units to filter by
 * @param {String} [sort] The optional sort order
 * @param {Number} limit The maximum number of acks to get
 * @param {Number} offset The number of acks to skip
 * @returns {Promise<Array<Object>>} The MOTD's acks
 */
export async function searchMotdAcks (motdId, query = '', units = [], sort = '+recent', limit, offset) {
  const response = await client.get(`/motd/${motdId}/acks`, {
    params: { query, units, sort, limit, offset }
  })
  return response.data
}

/**
 * Gets a user's acks
 * @param {String} userId The id of the user
 * @returns {Promise<Array<Object>>} The user's acks
 */
export async function getUserAcks (userId, limit, offset) {
  const response = await client.get(`/motd/acks/user/${userId}`, {
    params: { limit, offset }
  })
  return response.data
}
